<template>
    <div class="internal-sections add-style-blocks-mi">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">
                            {{ $t("message.clinics") }}
                        </div>
                        <div class="filter-internal-block">
                            <crm-input
                                :size="'small'"
                                :className="'w100'"
                                v-model="filterForm.search"
                                :icon="'el-icon-search'"
                            ></crm-input>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <crm-create-and-column-settings
                        @c-create="drawerCreate = true"
                        :columns="columns"
                        @c-change="updateColumn"
                    >
                    </crm-create-and-column-settings>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item>
                    {{ $t("message.clinics") }}
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="internal-sections-table">
            <table class="table-my-code table-bordered" v-loading="loadingData">
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.legal_entity_name.show">
                            {{ columns.legal_entity_name.title }}
                        </th>

                        <th v-if="columns.brand_name.show">
                            {{ columns.brand_name.title }}
                        </th>

                        <th v-if="columns.inn.show">
                            {{ columns.inn.title }}
                        </th>

                        <th v-if="columns.medical_organization_type.show">
                            {{ columns.medical_organization_type.title }}
                        </th>

                        <th v-if="columns.region.show">
                            {{ columns.region.title }}
                        </th>

                        <th v-if="columns.city_district.show">
                            {{ columns.city_district.title }}
                        </th>

                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th v-if="columns.profilLink.show" style="width: 150px">
                            {{ columns.profilLink.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.legal_entity_name.show">
                            <crm-input
                                :placeholder="columns.legal_entity_name.title"
                                v-model="filterForm.legal_entity_name"
                                :size="'mini'"
                            ></crm-input>
                        </th>

                        <th v-if="columns.brand_name.show">
                            <crm-input
                                :placeholder="columns.brand_name.title"
                                v-model="filterForm.brand_name"
                                :size="'mini'"
                            ></crm-input>
                        </th>

                        <th v-if="columns.inn.show">
                            <crm-input
                                :placeholder="columns.inn.title"
                                v-model="filterForm.inn"
                                :size="'mini'"
                            ></crm-input>
                        </th>

                        <th v-if="columns.medical_organization_type.show">
                            <select-organization-type
                                v-model="filterForm.type_id"
                                :size="'mini'"
                            >
                            </select-organization-type>
                        </th>

                        <th v-if="columns.region.show">
                            <el-select v-model="filterForm.region_id" @change="selectedRegionId(filterForm.region_id, true)" :placeholder="columns.region.title" filterable clearable size="mini">
                                <el-option  v-for="(region,index) in regions" :key="'region-'+index"
                                    :label="region.region_name"
                                    :value="region.id"
                                ></el-option>
                            </el-select>
                        </th>

                        <th v-if="columns.city_district.show">
                            <el-select v-model="filterForm.city_district_id" :placeholder="columns.city_district.title" filterable clearable size="mini">
                                <el-option  v-for="(city,index) in filtered_city_district" :key="'city-'+index"
                                    :label="city.name"
                                    :value="city.id"
                                ></el-option>
                            </el-select>
                        </th>

                        <th v-if="columns.created_at.show">
                            <crm-date-picker
                                :placeholder="columns.created_at.title"
                                v-model="filterForm.created_at"
                                :size="'mini'"
                            ></crm-date-picker>
                        </th>

                        <th v-if="columns.updated_at.show">
                            <crm-date-picker
                                :placeholder="columns.updated_at.title"
                                v-model="filterForm.updated_at"
                                :size="'mini'"
                            ></crm-date-picker>
                        </th>

                        <th class="settinW" v-if="columns.profilLink.show">
                            <el-input
                                :placeholder="columns.profilLink.title"
                                :disabled="true"
                                :size="'mini'"
                            >
                            </el-input>
                        </th>
                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="clinics in list"
                        :key="clinics.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">
                            {{ clinics.id }}
                        </td>

                        <td v-if="columns.legal_entity_name.show">
                            {{ clinics.legal_entity_name }}
                        </td>

                        <td v-if="columns.brand_name.show">
                            {{ clinics.brand_name }}
                        </td>

                        <td v-if="columns.inn.show">
                            {{ clinics.inn }}
                        </td>

                        <td v-if="columns.medical_organization_type.show">
                            {{
                                clinics.medical_organization_type
                                    ? clinics.medical_organization_type.name
                                    : ""
                            }}
                        </td>

                        <td v-if="columns.region.show">
                            {{ clinics.work_region ? clinics.work_region.region_name : '' }}
                        </td>

                        <td v-if="columns.city_district.show">
                            {{ clinics.work_city_district ? clinics.work_city_district.name : '' }}
                        </td>

                        <td v-if="columns.created_at.show">
                            {{ clinics.created_at }}
                        </td>

                        <td v-if="columns.updated_at.show">
                            {{ clinics.updated_at }}
                        </td>

                        <td v-if="columns.profilLink.show">
                            <el-button
                                class="style-link-prifil"
                                type="success"
                                size="small"
                                plain
                                @click="showProfil(clinics)"
                            >
                                <i class="el-icon-view"></i>
                                <span> Ko'rmoq </span>
                                <i class="el-icon-right"></i>
                            </el-button>
                        </td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <crm-settings
                                :name="$options.name"
                                :model="clinics"
                                :permissionShow="'clinicsController@update'"
                                :permissionDestroy="'clinicsController@destroy'"
                                :actions="actions"
                                @edit="edit"
                                @delete="destroy"
                            ></crm-settings>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my-pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>

        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                :wrapperClosable="false"
                size="95%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                    :status="true"
                ></crm-create>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdate"
                :wrapperClosable="false"
                size="95%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    :status="false"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
import CrmInput from '@/components/crm/crm-input.vue';
export default {
    name: "clinics",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
        CrmInput,
    },

    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            list: "clinics/list",
            columns: "clinics/columns",
            pagination: "clinics/pagination",
            statues: "clinics/statues",
            filter: "clinics/filter",
            sort: "clinics/sort",
            regions: "region/inventory",
            filtered_city_district: 'region/filtered_city_district',
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    created(){
        this.getRegions();
    },
    methods: {
        ...mapActions({
            updateList: "clinics/index",
            setPagination: "clinics/setPagination",
            updateSort: "clinics/updateSort",
            updateFilter: "clinics/updateFilter",
            updateColumn: "clinics/updateColumn",
            updatePagination: "clinics/updatePagination",
            show: "clinics/show",
            empty: "clinics/empty",
            delete: "clinics/destroy",
            refreshData: "clinics/refreshData",
            getRegions: "region/inventory",
            getCityDistricts: 'region/filteredCityDistrict',
        }),

        showProfil(clinic) {
            this.$router.push("/officeClinic/infoClinic/" + clinic.id);
        },
         selectedRegionId(region_id){ 

            if (this.filterForm.city_district_id) {
                this.filterForm.city_district_id = null;
            }

            if (region_id) {  
                let query = {region_id: region_id};
                this.getCityDistricts(query)
                .then((result) => {
                    
                }).catch((err) => {
                    console.log(err); 
                });
            }else{
                this.$store.commit('region/EMPTY_CITY_DISTRICT');
            }
        },
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('clinics/EMPTY_LIST');
        next();
    },
};
</script>
