import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
    props:{
        status: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            rules: "clinics/rules",
            model: "clinics/model",
            columns: "clinics/columns",
            regions: "region/inventory",
            legal_regions: "region/legal_inventory"
        }),
    },
    data(){
        return {
            loadingData: false,
            dialogImageUrl: "",
            dialogVisible: false,
            updateImage: [],
            selectedPosition: null,
            fileStatus: false,
            city_districts: [],
            work_city_districts: [],
            clinics: [],
            deal_clinics: [],
            loading: false,
            filtered_rules: {},
            likely_inns: [],
            visible_inn: false,
            isShow: false,
        }
    },
    created(){
        this.getRegions({protected_key: 'clinic'});
    },
    methods: {
        ...mapActions({
            getRegions: "region/inventory",
            getCityDistricts: 'region/filteredCityDistrict',
            empty: 'clinics/empty',
            remoteSearchMethod: 'doctors/remoteMethod',
            checkINNByAnotherInns: "clinics/checkINNByAnotherInns"
        }),
        add(){
            this.items.push({
                bank_name: "",
                r_s_bank: "",
                mfo: "",
                currency_id: "",
            });
        },
        deleteItem(index) {
          if (index != 0)  delete this.items.splice(index, 1);
        },

        add2(){
            this.building_informations.push({
                cadastre_number: "",
                cadastre_number: "",
                property_ownership_id: "",
                document_name: "",
                document_number: "",
                document_date: "",
                fayls: []
            });
        },
        deleteItem2(index) {
          if (index != 0)  delete this.building_informations.splice(index, 1);
        },

        submit(close = true) {
            let formData = new FormData();

            for (const key in this.form) {
                if (this.form[key]) {
                    formData.append(key, this.form[key]);
                }
                else if(this.form[key] == false){
                    formData.append(key, this.form[key]);
                }
                else {
                    formData.append('', this.form[key]);
                }
            }

            /**
             * Get Uplaod Images
             */
             if (_.size(this.updateImage) > 0) {
                for (const key in this.updateImage) {
                    if (this.updateImage.hasOwnProperty(key)) {
                        const element = this.updateImage[key];
                        formData.append(`images[${key}]`, element);
                    }
                }
            }

            let items = this.items.map(item => {
                return {
                    bank_name: item.bank_name,
                    r_s_bank: item.r_s_bank,
                    mfo: item.mfo,
                    currency_id: item.currency_id,
                }
            });
            this.buildFormData(formData, items, 'items');

            let building_informations = this.building_informations.map(item => {
                return {
                    cadastre_number: item.cadastre_number,
                    property_ownership_id: item.property_ownership_id,
                    document_name: item.document_name,
                    document_number: item.document_number,
                    document_date: item.document_date,
                    fayls: item.fayls
                }
            });
            this.buildFormData(formData, building_informations, 'building_informations');

            this.buildFormData(formData, this.old_building_informations, 'old_building_informations');
            this.buildFormData(formData, this.old_clinic_items, 'old_clinic_items');


            this.$refs["form"].validate((valid) => {
                if (valid && this.validateUplaodImages() && this.validateUplaodFiles()) {
                    this.loadingButton = true;
                    this.save(formData)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },

        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
              Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
              });
            } else {
              const value = data == null ? '' : data;

              formData.append(parentKey, value);
            }
        },
        validateUplaodImages() {
            // Validation for doctor image
            if (_.find(this.updateImage, function(o) { return (o.type != "image/jpeg" && o.type != "image/png") })) {
                this.$message({
                    message: this.$t('message.The image format must be JPEG, JPG, PNG'),
                    type: 'warning',
                    showClose: true
                });
                return false;
            }
            return true; 
        },

        validateUplaodFiles(){
            let files = [];
            let extensions = ['jpeg', 'jpg', 'png', 'svg', 'pdf', 'doc', 'docx', 'xls', 'xlsx'];
            let errors = [];

            if (!_.isEmpty(this.old_building_informations)) {
                _.forEach(this.old_building_informations, (elem, index) => {
                    if (!_.isEmpty(elem.fayls)) files.push(elem.fayls[index]);
                });
            }

            if (!_.isEmpty(this.building_informations)) {
                _.forEach(this.building_informations, (elem, index) => {
                    if (!_.isEmpty(elem.fayls)) files.push(elem.fayls[index]);
                });
            }

            function clean_code(elem){
                elem.forEach(item => {
                    let ext = _.last(_.split(item.name, '.'));
                    let has_ext = extensions.includes(ext);
                    if (!has_ext) {
                        if (!errors.includes(_.upperCase(ext))) {
                            errors.push(_.upperCase(ext));
                        }
                    }
                }); 
            }
            clean_code(files);

            if (!_.isEmpty(errors)) {
                const h = this.$createElement;
                let mistake = errors.map(mime => h('li', `${mime}` + '  kengaytmali fayl yuklay olmaysiz'));
                const message = h('ul', mistake);
                    this.$message({
                        message: message,
                        type: 'warning',
                        showClose: true
                    });
                return false;     
            }
            return true;
           
            // let result = (has_status) ? true : false;   
            // return result;  
        },
        
        updateImageList(file) {
            this.updateImage.push(file.raw);  
        },
        handleRemove(file) {
            if (file.id) {
                this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                    this.$alert(res);
                }).catch(err => {});
            } else {
                for (let i = 0; i < _.size(this.updateImage); i++) {
                    if (this.updateImage[i].name === file.name) {
                        this.updateImage.splice(i, 1);
                    }
                }
            }
        },

        /** Clinic Building Files */
        getItemPosition(id, fileStatus) {
            this.selectedPosition = id;
            this.fileStatus = fileStatus;
        },
        updateBuildingInformationFile(file){
            let id = this.selectedPosition;
            if (!this.status){
                if (!this.fileStatus) {
                    this.old_building_informations.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });           
                }else {
                    this.building_informations.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });  
                }
            }else{
                this.building_informations.forEach(item => {
                    if (item.id == id) {
                        item.fayls.push(file.raw);
                    }
                }); 
            }
        },
        handleBuildingInformationFileRemoveOld(file){
            for (let i = 0; i < _.size(this.old_building_informations); i++) {
                for (var j = 0; j < _.size(this.old_building_informations[i].fayls); j++) {
                    if (this.old_building_informations[i].fayls[j].name === file.name) { 
                        this.old_building_informations[i].fayls.splice(j, 1);
                    }
                }
            }
        },
        handleBuildingInformationFileRemove(file){
            for (let i = 0; i < _.size(this.building_informations); i++) {
                for (var j = 0; j < _.size(this.building_informations[i].fayls); j++) {
                    if (this.building_informations[i].fayls[j].name === file.name) { 
                        this.building_informations[i].fayls.splice(j, 1);
                    }
                }
            }
        },
        /** Clinic Building Files */

        selectedRegionId(region_id, status){
            if (status) {
                if (this.form.city_district_id) {
                    this.form.city_district_id = null;
                }

                if (region_id) {  
                    let query = {region_id: region_id};
                    this.getCityDistricts(query).then((res) => {
                        if (res.filteredCityDistricts) {
                           this.city_districts = res.filteredCityDistricts;
                        }
                    });
                }else{
                    this.city_districts = [];
                }
            }else{ 
                if (this.form.work_city_id) {
                    this.form.work_city_id = null;
                }

                if (region_id) {  
                    let query = {region_id: region_id};
                    this.getCityDistricts(query).then((res) => {
                        this.work_city_districts = res.filteredCityDistricts;
                    });
                }else{
                    this.work_city_districts = [];
                }
            }
        },
        remoteMethod(query){
            if (_.isNumber(query)){
                this.loading = true;
                this.remoteSearchMethod({query: query}).then((res) => {
                    if (res.clinics) {
                        this.loading = false;
                        this.clinics = res.clinics;
                    }
                }).catch((err) => {
                    this.loading = false; 
                    console.log('err', err);
                });
            }
            else{
                if (query) {
                    this.loading = true;
                    setTimeout(() => {
                        this.remoteSearchMethod({query: query})
                        .then((res) => {
                            if (res.clinics) {
                                this.loading = false;
                                this.clinics = res.clinics;
                            }
                        }).catch((err) => {
                            this.loading = false;
                            console.log(err);
                        });
                    }, 1000);
                }else{
                    this.clinics = [];
                }
            }
        },
        remoteMethodDeal(letter){
            if (_.isNumber(letter)) {
                this.loading = true;
                this.remoteSearchMethod({query: letter}).then((res) => {
                    if (res.clinics) {
                        this.loading = false;
                        this.deal_clinics = res.clinics;
                    }
                }).catch((err) => {
                    this.loading = false; 
                    console.log('err', err);
                });
            }
            else{
                if(letter){
                    this.loading = true;
                    setTimeout(() => {
                        this.remoteSearchMethod({query: letter}).then((res) => {
                            this.loading = false;
                            this.deal_clinics = res.clinics;
                        }).catch((err) => {this.loading = false; this.$alert(err)}); 
                    }, 1000);
                }
                else{this.deal_clinics = [];}
            }
        },
        setRules(rules){
            if (rules) {
                this.filtered_rules = rules;
            }
        },
        filterINN(){
            if (this.form.inn.length == 9) {
                let debounce_func = _.debounce(this.loadInn, 500);
                debounce_func();
            }
        },
        loadInn(){
            if (!this.loadingData) {
                this.loadingData = true;
                let query = {filter_inn: this.form.inn, clinic_id: this.form.id};
                this.checkINNByAnotherInns(query).then((res) => {
                    if (res.likely_inns) {
                        this.loadingData = false;
                        this.likely_inns = res.likely_inns;
                        this.isShow = !_.isEmpty(res.likely_inns) ? true : false;
                    }
                }).catch((err) => {
                    this.loadingData = false;
                });
            }
        },
        async clearData(){
            if (_.isEmpty(this.form.inn)) {
                this.isShow = false;
                this.likely_inns = [];
            }
        },
        tableRowClassName({row, rowIndex}){
            return 'warning-row';
        },
        isLetter(e){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z\s]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        },
        clinicTypeChange(status){
            if (status && _.isBoolean(status)){
                this.getRegions();
            }else {
                this.getRegions({protected_key: 'clinic'});
            }
        }
    }
}